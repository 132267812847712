/* Модуль spinner.module.css определяет стили компонента Spinner.tsx */

.spinner {
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.spinner__loader {
    display: inline-block;
    text-align: center;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #ec7623;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: bp-spin 2s linear infinite;
}

.spinner__description {
    color: white;
    margin: 10px auto 0;
}

@keyframes bp-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
