/* Модуль header.module.css определяет стили компонента Header.tsx */

.header {
    margin-top: 11px;
    margin-bottom: 22px;
    display: flex;
}
.header-image {
    width: 180px;
}
.header-profile {
    text-align: right;
    margin-left: auto;
    font-size: 13px;
    margin-top: 5px;
}
.header-profile-name {
    font-weight: bold;
}
.header-profile-company {
    opacity: 0.5;
}

@media (max-width: 767px) {
    .header {
        display: flex;
        justify-content: center;
        margin-bottom: 28px;
    }
}
