.formTable__container {
    background: #ffffff;
    border-radius: 2px;
    width: 100%;
    padding: 0 0 20px;
}

.formTable__title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: rgba(101, 114, 130, 0.75);
    padding: 8px 35px 0;
}

.formTable__content {
    padding: 8px 35px 7px;
}

@media (max-width: 767px) {
    .formTable__title,
    .formTable__content {
        padding: 8px 22px 7px;
    }
}
