/* Модуль device-login.module.css определяет стили компонента DeviceLoginPage.tsx */

.deviceLogin__container {
    background: #ffffff;
    border-radius: 2px;
    width: 100%;
    padding: 30px 20px 20px 20px;
}
.deviceLogin__containerHeading {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;
}
.deviceLogin__containerMessage {
    opacity: 0.7;
    font-size: 15px;
    margin-bottom: 14px;
}
.deviceLogin__containerDescription {
    opacity: 0.5;
    font-size: 12px;
    margin-top: 7px;
    margin-bottom: 18px;
}
.deviceLogin__buttonLogin {
    padding: 12px 36px;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border-radius: 2px;
    text-align: center;
    background: #4a90e2;
    display: block;
    border: 0;
}
.deviceLogin__containerError {
    margin-top: 10px;
    margin-bottom: 10px;
    color: red;
}
.deviceLogin__buttonLogin[disabled] {
    opacity: 0.7;
}