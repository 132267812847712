.modalForm__back {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    z-index: 5;
}

.modalForm {
    max-width: 600px;
    padding: 23px 30px 35px;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    width: 60%;
    max-height: 90%;
    z-index: 99;
}

.modalForm::-webkit-scrollbar-thumb {
    border: 1px solid black;
    background-color: #b8785c;
}

.modalForm::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
}

.modalForm::-webkit-scrollbar-thumb {
    border: 1px solid red;
}

@media (max-width: 767px) {
    .modalForm {
        padding: 21px 22px 45px;
        width: auto;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        max-height: none;
    }
}

.modalForm__headerContainer {
    display: block;
}


.modalForm__nameForm,
.modalForm__closeButton {
    background: #ec7623;
    border-radius: 2px;
    padding: 4px 15px;
    font-weight: bold;
    font-size: 10px;
    line-height: 19px;
    color: #ffffff;
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
    border: none;
}

.modalForm__closeButton {
    background: #4a90e2;
    cursor: pointer;
    float: right;
    margin-right: 0;
}

.modalForm__closeButton[disabled],
.modalForm__buttonSave[disabled],
.modalForm__buttonFollow[disabled] {
    opacity: 0.5;
}

.modalForm__title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: -5px;
    color: #657282;
}

.modalForm__label {
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
}

.modalForm__label:not(:last-child) {
    margin-bottom: 14px;
}

.modalForm__inputText {
    background: #ffffff;
    border: 1px solid #cecece;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px #cecece;
    border-radius: 0px 0px 2px 2px;
    padding: 7px 13px;
    font-size: 14px;
    line-height: 24px;
}

.modalForm__inputText::placeholder {
    color: rgba(101, 114, 130, 0.5);
}

.modalForm__inputSubText {
    padding-top: 3px;
    font-size: 13px;
    line-height: 19px;
    color: rgba(101, 114, 130, 0.5);
}

.modalForm__inputError {
    color: rgba(255, 0, 0, 0.5);
}

.modalForm__buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

@media (max-width: 767px) {
    .modalForm__buttonContainer {
        flex-direction: column;
    }
}

.modalForm__buttonSave,
.modalForm__buttonFollow {
    padding: 12px 0;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    border: 0;
}

.modalForm__buttonSave {
    width: 40%;
    background: #4a90e2;
}

.modalForm__buttonFollow {
    width: 55%;
    background: #ec7623;
}

@media (max-width: 767px) {
    .modalForm__buttonFollow,
    .modalForm__buttonSave {
        width: 100%;
    }

    .modalForm__buttonSave,
    .modalForm__buttonFollow {
        margin-bottom: 12px;
    }
}

.modalForm__formDisabled select {
    background: #eee;
}