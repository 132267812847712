/* Модуль forms-page.module.css определяет стили компонента FormsPage.tsx */

.button_blue,
.button_add {
    padding: 9px 20px 10px;
    font-size: 11px;
    line-height: 12px;
    font-weight: 700;
    color: white;
    border: 0;
    background: #4a90e2;
    border-radius: 20px;
    margin-right: 10px;
    text-transform: uppercase;
}

.button_blue {
    opacity: 0.5;
}

.button_blue[disabled] {
    opacity: 1;
}

.button_orange {
    padding: 9px 20px 10px;
    font-size: 11px;
    line-height: 12px;
    font-weight: 700;
    color: white;
    margin-left: 10px;
    background: #ec7623;
    border-radius: 20px;
    text-transform: uppercase;
    border: 0;
}

.button_orange {
    opacity: 0.5;
}

.button_orange[disabled] {
    opacity: 1;
}

@media (max-width: 767px) {
    .button_blue,
    .button_orange {
        margin: 0;
        width: 100%;
    }

    .button_blue:not(:last-child),
    .button_orange:not(:last-child) {
        margin-bottom: 10px;
    }
}

.formHeader {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.formHeaderActions {
    margin-left: auto;
    margin-right: -10px;
}

@media (max-width: 767px) {
    .formHeader, .formHeaderActions {
        flex-direction: column;
        align-items: inherit;
        margin-left: 0;
        margin-right: 0;
    }
}

.formTable__container {
    background: #ffffff;
    border-radius: 2px;
    width: 100%;
    padding: 0 0 20px;
}

.formTable__nothing {
    padding: 12px 35px;
    font-size: 14px;
    opacity: 0.5;
}

.formTable__title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: rgba(101, 114, 130, 0.75);
    padding: 8px 35px 7px;
}

@media (max-width: 767px) {
    .formTable__title {
        padding: 8px 22px 7px;
    }
}

.formTable__item {
    padding: 16px 35px 18px;
    position: relative;
    border-radius: 2px;
    transition: background-color 0.1s;
    cursor: pointer;
    outline: none !important;
}

@media (max-width: 767px) {
    .formTable__item {
        padding: 16px 22px 18px;
        position: relative;
        border-radius: 2px;
        transition: background-color 0.1s;
        cursor: pointer;
    }
}

.formTable__item:hover {
    box-shadow: inset 4px 0 0 rgba(255, 255, 255, 0.25);
    outline: none;
}

.formTable__itemText {
    color: #657282;
    font-size: 11px;
    line-height: 18px;
}

.formTable__itemHead {
    display: flex;
    justify-content: space-between;
    line-height: 14px;
    font-weight: 700;
    color: rgba(101, 114, 130, 0.75);
    padding-bottom: 2px;
    font-size: 12px;
}

.formTable__itemDate {
    text-align: right;
    opacity: 0.6;
}

.formTable__itemBadge {
    padding: 3px 12px;
    background: #4a90e2;
    transition: background-color 0.3s;
    margin-top: 3px;
    color: white;
    display: inline-block;
    border-radius: 5px;
    font-size: 11px;
    margin-right: 5px;
    font-weight: bold;
}

.formTable__itemName {
    text-align: left;
    color: #333;
}

.pagination__item {
    border: 1px solid #90b2c9;
    font-size: 14px;
    line-height: 21px;
    color: #90b2c9;
    padding: 7px 13px;
    border-left: 0;
}

@media (max-width: 767px) {
    .pagination__item {
        padding: 7px 6px;
    }
}

.pagination__item > a {
    color: #90b2c9;
}

.pagination__item:first-child {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #90b2c9;
}

.pagination__item:last-child {
    border-radius: 0 4px 4px 0;
}

.pagination__item__active {
    background-color: aliceblue;
}


.button_add {
    background: #5cb85c;
    border-radius: 0;
}

.button_add:first-child {
    margin-top: 15px;
}