/* Модуль not-found.module.css определяет стили компонента NotFoundPage.tsx */

.notFound__container {
    background: #ffffff;
    border-radius: 2px;
    width: 100%;
    padding: 30px 20px 20px 20px;
}
.notFound__containerHeading {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;
}
.notFound__containerButton {
    padding: 12px 36px;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border-radius: 2px;
    text-align: center;
    background: #4a90e2;
    display: block;
    border: 0;
}