/* Модуль certificate-selection.module.css определяет стили компонента CertificateSelectionPage.tsx */

.certificateSelection__container {
    background: #ffffff;
    border-radius: 2px;
    width: 100%;
    padding: 30px 20px 20px 20px;
}
.certificateSelection__containerHeading {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;
}
.certificateSelection__containerMessage {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 14px;
}
.certificateSelection__buttonSelect {
    padding: 12px 0;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border-radius: 2px;
    text-align: center;
    border: 0;
    width: 40%;
    background: #4a90e2;
}
.certificateSelection__buttonSelect[disabled] {
    opacity: 0.6;
}

.certificateTable__item {
    padding: 16px 12px 18px;
    position: relative;
    border-radius: 2px;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
}
.certificateTable__img {
    margin-right: 10px;
}
@media (max-width: 767px) {
    .certificateTable__item {
        padding: 16px 12px 18px;
        position: relative;
        border-radius: 2px;
        transition: 0.3s;
        cursor: pointer;
    }
    .certificateSelection__buttonSelect {
        width: 100%;
    }
}
.certificateTable__itemSelected,
.certificateTable__item {
    outline: none;
}
.certificateTable__itemText {
    color: #657282;
    font-size: 13px;
    line-height: 18px;
}
.certificateTable__itemHead {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    line-height: 14px;
    font-weight: 700;
    color: rgba(101, 114, 130, 0.75);
    padding-bottom: 2px;
}
.certificateTable__itemDate {
    text-align: right;
}
.certificateTable__itemName {
    text-align: left;
    overflow-wrap: anywhere;
    opacity: 0.6;
}