/* Модуль error-page.module.css определяет стили компонента ErrorPage.tsx */

.errorView__container {
    background: #ffffff;
    border-radius: 2px;
    width: 100%;
    padding: 30px 20px 20px 20px;
}
.errorView__containerHeading {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;
}
.errorView__containerDescription {
    opacity: 0.7;
    font-size: 14px;
    margin-bottom: 14px;
}
.errorView__containerButton {
    padding: 12px 36px;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border-radius: 2px;
    text-align: center;
    background: #ec7623;
    display: block;
    border: 0;
}