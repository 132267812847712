/* Модуль global.css определяет глобальные стили клиентской части системы. */

html, body, #app {
    height: 100%;
    margin: 0;
    font-family: "Open Sans", sans-serif;
}

#app {
    display: table;
    width: 100%;
}

a:hover {
    text-decoration: none;
}

button {
    cursor: pointer;
}

button:focus,
input:focus {
    outline: none;
}

.max-container {
    max-width: 720px;
    margin: auto;
    padding: 25px;
}

.pagination {
    margin: 0;
    display: flex;
    list-style: none;
    border-radius: 0.25rem;
    padding: 10px 35px 0;
}

@media (max-width: 767px) {
    .pagination {
        padding: 10px 22px 0;
    }

    .max-container {
        padding: 29px 19px 26px 22px;
    }
}

.form-control {
    display: block;
    background: #ffffff;
    border: 1px solid #cecece;
    box-sizing: border-box;
    box-shadow: 0 1px 1px #cecece;
    border-radius: 0 0 2px 2px;
    padding: 7px 13px;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
}
.form-control::placeholder {
    color: rgba(101, 114, 130, 0.5);
}
label.control-label,
legend {
    padding-top: 3px;
    font-size: 13px;
    line-height: 19px;
    color: rgba(101, 114, 130, 0.7);
}
legend {
    font-weight: bold;
    font-size: 15px;
    color: rgba(101, 114, 130, 1);
    padding: 0;
    margin: 0;
    border: none;
}
input[type="checkbox"] {
    margin: 15px 10px 10px 0;
}
.field-description {
    margin: 5px 0;
}

.rjsf .control-label {
    margin-bottom: 0;
    display: flex;
    text-transform: capitalize;
}

.rjsf .field-number,
.rjsf .field-string,
.rjsf .field-integer {
    margin-top: 5px;
}
.rjsf .field-object {
    margin: 15px 0;
    border: none;
    border-left: 1px solid #dedede;
    padding: 0 0 0 15px;
}
.rjsf .form-group {
    margin-bottom: 5px;
}
.rjsf .form-control {
    cursor: pointer;
}
.rjsf > .field-object {
    border: none;
    padding-left: 0;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto auto auto -20px;
}

.pagination > .active > a,
.pagination > .active > a:focus {
    background-color: #939ca7;
    border-color: #939ca7;
}
.pagination > li > a,
.pagination > li > span{
    color: #939ca7;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: #939ca7;
    border-color: #939ca7;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    color: #333333;
}

.bjs-container {
    height: 450px!important;
}
.djs-search-container,
.bjs-powered-by {
    display: none;
}
@media (max-width: 500px) {
    .bjs-container {
        height: 300px!important;
    }
}